.program {
    border-bottom: 1px solid #4A3636;
    padding-bottom: 140px;
}

.program-top {
    width: 100%;
    display: inline-flex;
}

.program-title {
    width: 65%;
    font-family: ZvinRegular, 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-transform: uppercase;
    font-size: 120px;
    border-right: 1px solid #4A3636;
    padding: 170px 50px 0 0;
}

.program-title-first {
    text-align: left;
}

.program-title-second {
    text-align: end;
}

.program-nav {
    width: 30%;
    display: inline-flex;
    align-items: flex-end;
    justify-content: space-evenly;
}

.program-nav-btn {
    width: 170px;
    height: 170px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.program-nav-btn-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #fff;
    width: 170px;
    height: 170px;
    transition: all .2s ease-in-out;
}

.program-nav-btn:hover {
    .program-nav-btn-inner {
        width: 117px;
        height: 117px;
    }
}

.program-bottom {
    padding-top: 80px;
}

.program-slide {
    display: flex !important;
    height: 395px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
}

.program-slide:nth-child(3) {
    img {
        width: 318px;
    }
}

@media screen and (max-width: 1200px) {
    .program {
        padding-bottom: 70px;
    }
    .program-top {
        display: flex;
        flex-direction: column;
    }
    .program-title {
        width: 100%;
        padding: 70px 0 0 0;
        font-size: 40px;
        line-height: 48px;
        border: none;
        max-width: 400px;
        align-self: center;
    }
    .program-nav {
        display: none;
    }
    .program-bottom {
        padding-top: 35px;
    }
    .program-slide {
        margin-right: 10px;
        height: 320px;
        justify-content: flex-start;
        img {
            height: 320px;
        }
    }
}
