@font-face {
  font-family: ZvinBlack;
  src: url(./assets/fonts/ZvinSerif-Black.woff);
}

@font-face {
  font-family: ZvinBold;
  src: url(./assets/fonts/ZvinSerif-Bold.woff);
  font-weight: bold;
}

@font-face {
  font-family: ZvinMedium;
  src: url(./assets/fonts/ZvinSerif-Medium.woff);
}

@font-face {
  font-family: ZvinRegular;
  src: url(./assets/fonts/ZvinSerif-Regular.woff);
}

@font-face {
  font-family: ZvinSemiBold;
  src: url(./assets/fonts/ZvinSerif-SemiBold.woff);
}

@font-face {
  font-family: Yauza;
  src: url(./assets/fonts/yauzatygra.ttf);
}

body {
  margin: 0;
  font-family: ZvinMedium, 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4A3636;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
