.info-partners {
    padding: 150px 0;
    border-bottom: 1px solid #4A3636;
}

.info-partners-title {
    font-family: ZvinRegular, 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-transform: uppercase;
    font-size: 120px;
}

.info-partners-list {
    padding-top: 70px;
}

.info-partners-list-inner {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 14px;
}

.info-partners-list-item {
    width: 200px;
    height: 90px;
    background: #FFFFFF33;
    border: 1px solid #fff;
    border-radius: 30px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    transition: all .2s ease-in-out;
    cursor: pointer;

    img {
        width: 150px;
    }
}

.info-partners-list-item:hover {
    background: #fff;
}

.info-partners-list-item:nth-child(4) {
    img {
        width: 80px;
    }
}
.info-partners-list-item:nth-child(8) {
    img {
        width: 120px;
        padding-top: 13px;
    }
}
.info-partners-list-item:nth-child(9) {
    img {
        width: 75px;
    }
}
.info-partners-list-item:nth-child(10) {
    img {
        width: 75px;
    }
}
.info-partners-list-item:nth-child(12) {
    img {
        width: 120px;
    }
}

@media screen and (max-width: 1200px) {
    .info-partners {
        padding: 70px 0;
    }
    .info-partners-title {
        font-size: 40px;
        line-height: 48px;
    }
    .info-partners-list {
        flex: 1;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        padding-top: 35px;
    }
    .info-partners-list-inner {
        display: flex;
        flex-wrap: nowrap;
    }
    .info-partners-list-item:hover {
        background: #FFFFFF33;
    }
}
