.footer {
    font-family: ZvinRegular, 'Droid Sans', 'Helvetica Neue', sans-serif;
    padding: 150px 0 0 0;
}

.footer-top-first {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

.footer-top-first-left {
    text-transform: uppercase;
    font-size: 120px;
}

.footer-top-first-right {
    display: flex;
    font-size: 25px;
    align-self: center;
}

.footer-top-second {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
}

.footer-top-second-left {
    display: flex;
    font-size: 25px;
    text-align: right;
    align-self: center;
}

.footer-top-second-right {
    text-transform: uppercase;
    font-size: 120px;
    margin-left: 50px;
}

.footer-mid {
    background: url("../../assets/images/ornament.svg");
    height: 27px;
    margin: 30px 0;
}

.footer-bottom {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-bottom-first-price {
    font-size: 35px;
    background: #FFFFFF66;
    max-width: 795px;
    height: 63px;
    border-radius: 15px;
    display: flex;
    align-self: center;
    justify-content: space-evenly;

    img {
        width: 33px;
    }

    span {
        display: flex;
        align-self: center;
    }
}

.footer-bottom-buy {
    display: inline-flex;
    margin-top: 35px;
}

.footer-bottom-buy-item {
    position: relative;
    width: 305px;
    height: 180px;
    background: #fff;
    border-radius: 40px;
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    cursor: pointer;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

.footer-bottom-buy-item-arrow {
    position: absolute;
    top: 35px;
    right: 30px;
    width: 23px;
    transform: rotate(-45deg);
    transition: all .2s ease-in-out;
}

.footer-bottom-buy-item:hover {
    background: #FFFFFF80;

    .footer-bottom-buy-item-arrow {
        transform: rotate(0deg);
    }
}

.footer-bottom-buy-item:nth-child(2) {
    margin-left: 20px;
}


.footer-bottom-buy-item-text {
    font-size: 25px;
    align-self: end;
    color: #4A3636;
    text-decoration: none;
}

.footer-bottom-second {
    display: flex;
    align-self: center;
    border-right: 1px solid #4A3636;
    padding: 0 50px 0 70px;
    img {
        width: 318px;
    }
}

.footer-bottom-third {
    padding: 20px 35px 20px 50px;
}

.footer-bottom-third-title {
    font-size: 35px;
}

.footer-contact-wrapper {
    display: inline-flex;
    padding-top: 35px;
}

.footer-contact-btn {
    width: 170px;
    height: 170px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-contact-btn-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #fff;
    width: 170px;
    height: 170px;
    transition: all .2s ease-in-out;
}

.footer-contact-btn:hover {
    .footer-contact-btn-inner {
        width: 117px;
        height: 117px;
    }
}

.footer-contact-btn:nth-child(2) {
    margin-left: 35px;
}

.footer-footer {
    font-size: 25px;
    text-align: center;
    padding: 100px 0 50px;
    img {
        display: none;
    }
}

.buy-cards-img-mob {
    display: none;
}

.footer-bottom-text-mob {
    display: none;
}
.footer-mid-mob {
    display: none;
}

@media screen and (max-width: 1200px) {
    .footer {
        padding-top: 70px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .footer-top {
        max-width: 700px;
    }
    .footer-top-first-left {
        font-size: 40px;
        line-height: 48px;
    }
    .footer-top-first-right {
        display: none;
    }
    .footer-top-second-right {
        font-size: 40px;
        line-height: 48px;
    }
    .footer-top-second-left {
        display: none;
    }
    .footer-bottom {
        padding-top: 20px;
    }
    .footer-bottom-first-price {
        font-size: 17px;
        line-height: 23px;
        width: 355px;
        height: 36px;
        border-radius: 10px;
        img {
            width: 15px;
        }
    }
    .footer-mid {
        display: none;
    }
    .footer-bottom-first {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .footer-bottom-buy {
        flex-wrap: wrap;
        width: 100%;
        gap: 10px;
        justify-content: center;
    }
    .footer-bottom-buy-item {
        width: 315px;
        height: 90px;
        border-radius: 30px;
        padding: 20px;
    }
    .footer-bottom-buy-item:hover {
        background: #FFF;
    }

    .footer-bottom-buy-item:nth-child(2) {
        margin-left: 0;
    }
    .footer-bottom-buy-item-text {
        font-size: 16px;
        line-height: 19px;
    }
    .footer-bottom-buy-item-arrow {
        top: 95px;
        right: 20px;
        width: 23px;
    }
    .buy-cards-img {
        display: none;
    }
    .buy-cards-img-mob {
        display: flex;
    }
    .footer-bottom-text-mob {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-top: 15px;
        div {
            width: 100%;
        }
    }
    .footer-mid-mob {
        display: flex;
        width: 100%;
        background: url("../../assets/images/ornament.svg");
        height: 27px;
        margin: 20px 0;
    }
    .footer-bottom-second {
        display: none;
    }
    .footer-bottom-third {
        font-size: 35px;
        line-height: 35px;
        display: inline-flex;
        padding: 10px;
    }
    .footer-contact-wrapper {
        padding-top: 0;
        align-items: center;
    }
    .footer-contact-btn {
        width: 60px;
        height: 60px;
    }

    .footer-contact-btn:hover {
        .footer-contact-btn-inner {
            width: 60px;
            height: 60px;
        }
    }
    .footer-contact-btn:nth-child(2) {
        margin-left: 10px;
    }
    .footer-contact-btn-inner {
        width: 60px;
        height: 60px;
        img {
            width: 19px;
        }
    }
    .footer-contact-btn-inner:hover {
        width: 60px;
        height: 60px;
    }
    .footer-bottom-third-title {
        max-width: 230px;
    }
    .footer-footer {
        padding: 50px 0 20px;
        img {
            display: flex;
            margin-bottom: 50px;
            width: 318px;
        }
    }
}
