.partners {
    border-bottom: 1px solid #4A3636;
    padding-bottom: 150px;
}

.partners-top {
    margin-bottom: 30px;
    padding-bottom: 20px;
    display: inline-flex;
    width: 100%;
}

.partners-title {
    width: 50%;
    font-family: ZvinRegular, 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-transform: uppercase;
    border-right: 1px solid #4A3636;
    font-size: 120px;
    padding-right: 50px;
}

.partners-title-first {
    padding-top: 150px;
    text-align: left;
    display: flex;
    align-items: center;

    img {
        width: 72px;
        margin-left: 40px;
    }
}

.partners-title-second {
    text-align: end;
}

.partners-banners-wrapper {
    width: 50%;
    display: flex;
    align-items: flex-end;
}

.partners-banners-inner {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
}

.partners-banner-item {
    text-decoration: none;
    color: #4A3636;
    position: relative;
    width: 305px;
    height: 220px;
    background: #fff;
    border-radius: 40px;
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.partners-banner-item-arrow {
    position: absolute;
    top: 35px;
    right: 30px;
    width: 23px;
    transform: rotate(-45deg);
    transition: all .2s ease-in-out;
}

.partners-banner-item:hover {
    background: #FFFFFF80;

    .partners-banner-item-arrow {
        transform: rotate(0deg);
    }
}

.partners-banner-item:nth-child(2) {
    margin-left: 20px;
}


.partners-banner-item-text {
    font-size: 25px;
    font-family: ZvinRegular, 'Droid Sans', 'Helvetica Neue', sans-serif;
    align-self: end;
}


.partners-mid {
    background: url("../../assets/images/ornament.svg");
    height: 27px;
}

.partners-bottom {
    display: inline-flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 50px;
    justify-content: center;
}

.partners-bottom-item {
    position: relative;
    width: 355px;
    height: 160px;
    background: #fff;
    border-radius: 35px;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    cursor: pointer;
    color: #4A3636;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

.partners-bottom-item-arrow {
    position: absolute;
    top: 35px;
    right: 30px;
    width: 23px;
    transform: rotate(-45deg);
    transition: all .2s ease-in-out;
}

.partners-bottom-item:hover {
    background: #FFFFFF80;

    .partners-bottom-item-arrow {
        transform: rotate(0deg);
    }
}

.partners-bottom-item-logo {
    width: 174px;
}

@media screen and (min-width: 1201px) and (max-width: 1700px) {
    .partners-title {
        font-size: 100px;
        line-height: 100px;
    }
    .partners-title-first {
        padding-top: 120px;
    }
}

@media screen and (max-width: 1200px) {
    .partners {
        padding-bottom: 70px;
    }
    .partners-top {
        flex-direction: column;
        margin-bottom: 10px;
    }
    .partners-title {
        width: 100%;
        font-size: 40px;
        line-height: 48px;
        padding-right: 0;
        padding-bottom: 35px;
        border: 0;
        align-self: center;
        max-width: 400px;
    }

    .partners-title-first {
        padding-top: 60px;
        img {
            display: none;
        }
    }
    .partners-banners-wrapper {
        width: 100%;
    }
    .partners-banners-inner {
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
    }

    .partners-banner-item {
        width: 315px;
        height: 86px;
        border-radius: 28px;
        padding: 20px;
        img {
           height: 45px;
        }
        .partners-banner-item-arrow {
            top: 10px;
            right: 20px;
            width: 23px;
        }
    }
    .partners-banner-item:hover {
        background: #FFF;
    }

    .partners-banner-item:nth-child(2) {
        margin-left: 0;
    }

    .partners-banner-item-text {
        font-size: 16px;
        line-height: 19px;
        width: 100%;
        br {
            display: none;
        }
    }
    .partners-bottom {
        margin-top: 30px;
        gap: 5px;
        justify-content: center;
    }
    .partners-bottom-item {
        position: relative;
        width: 135px;
        height: 127px;
        background: #fff;
        border-radius: 35px;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .partners-bottom-item:hover {
        background: #FFF;
    }
    .partners-bottom-item-arrow {
        display: none;
    }
    .partners-bottom-item-logo {
        width: 133px;
    }
}
