.header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #4A3636;
    font-size: 20px;
}

.navigation {
    display: inline-flex;
    justify-content: space-between;
}

.navigation-item{
    cursor: pointer;
    margin-left: 20px;
    color: #4A3636;
    text-decoration: none;
}

.navigation-burger {
    display: none;
}

@media screen and (max-width: 1200px) {
    .header {
        padding-bottom: 10px;
    }
    .navigation {
        display: none;
    }
    .navigation-item{
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 35px;
        margin-left: 0;
    }
    .navigation-burger {
        display: flex;
    }
}
