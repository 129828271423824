.banner {
    background-image: url("../../assets/images/banner.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
    background-size: 530px;
    height: 900px;
    border-bottom: 1px solid #4A3636;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.banner-date {
    position: relative;
    padding-top: 100px;
    font-size: 220px;
    font-family: Yauza, 'Droid Sans', 'Helvetica Neue', sans-serif;
    line-height: 220px;
}

.buy-btn {
    position: absolute;
    bottom: 95px;
    z-index: 0;
    cursor: pointer;
    transition: all .2s ease-in-out;

    .buy-btn-img {
        transform: rotate(16deg);
        opacity: 0.7;
        transition: all .2s ease-in-out;
    }
}

.buy-btn:hover {
    .buy-btn-img {
        opacity: 1;
        transform: rotate(0deg);
    }
}

.banner-date-text {
    z-index: 1;
    position: absolute;
}

.banner-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0 120px 0;
}

.banner-info-sponsors {
    display: inline-flex;
}

.banner-info-sponsors-item {
    width: 240px;
    height: 85px;
    display: flex;
    align-self: center;
    justify-content: center;
    background: #FFFFFF99;
    border-radius: 23px;
    transition: all .2s ease-in-out;

    img {
        width: 185px;
    }
}

.banner-info-sponsors-item:hover {
    background: #fff;
}

.banner-info-sponsors-item:nth-child(2) {
    margin-left: 25px;
}

.banner-info-text {
    max-width: 480px;
}

.banner-info-text-title {
    font-family: ZvinRegular, 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-transform: uppercase;
    font-size: 45px;
    margin-bottom: 20px;
}

.banner-info-text-location {
    display: inline-flex;
    align-items: center;
    font-size: 25px;

    img {
        margin-right: 5px;
    }
}

.banner-date-mob {
    display: none;
}

.banner-img-mob {
    display: none;
}

.banner-slide-text-mob {
    display: none;
}

@media screen and (min-width: 1201px) and (max-width: 1700px) {
    .banner-date-text {
        font-size: 170px;
        line-height: 170px;
    }
    .banner {
        background-size: 380px;
        height: 715px;
    }
    .banner-info {
        padding-bottom: 40px;
    }
    .buy-btn {
        bottom: 20px;
    }
    .banner-info-text {
       text-align: right;
    }
    .banner-info-text-title {
        font-size: 27.5px;
    }
    .banner-info-text-location {
        font-size: 21px;
    }
}

@media screen and (max-width: 1200px) {
    .banner {
        height: 100%;
        background-image: none;
    }

    .banner-info {
        align-items: center;
    }
    .banner-info-sponsors {
        justify-content: space-between;
        width: 370px;
    }

    .banner-info-sponsors-item {
        width: 173px;
        height: 60px;
        border-radius: 13px;

        img {
            width: 141px;
        }
    }
    .banner-info-sponsors-item:hover {
        background: #FFFFFF99;
    }

    .banner-info-sponsors-item:nth-child(2) {
        margin-left: 0;
    }

    .banner-date {
        display: none;
    }

    .banner-date-mob {
        font-family: Yauza, 'Droid Sans', 'Helvetica Neue', sans-serif;
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        width: 370px;
    }

    .banner-date-mob-top {
        display: inline-flex;
    }

    .banner-date-mob-top-first {
        font-size: 100px;
        line-height: 100px;
    }

    .banner-date-mob-top-second {
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        text-transform: capitalize;
        font-family: ZvinRegular, 'Droid Sans', 'Helvetica Neue', sans-serif;
        align-self: end;
        padding-bottom: 10px;

        img {
            margin-right: 5px;
        }
    }

    .banner-date-mob-bottom {
        font-size: 100px;
        line-height: 100px;
        text-align: end;
        padding-top: 10px;
        position: relative;
    }

    .banner-date-mob-bottom-text {
        font-size: 100px;
        line-height: 100px;
        text-align: end;
        padding-top: 10px;
        position: relative;
        z-index: 1;
    }

    .banner-info {
        width: 100%;
        padding-bottom: 0;
    }

    .banner-info-text {
        display: none;
    }
    .buy-btn {
        top: 0;
        img {
            width: 165px;
        }
    }
    .banner-img-mob {
        display: flex;
        justify-content: center;
        align-self: center;
        padding-top: 35px;
        img {
            width: 300px;
        }
    }
    .banner-slide-text-mob {
        display: flex;
        font-size: 30px;
        font-family: ZvinRegular, 'Droid Sans', 'Helvetica Neue', sans-serif;
        text-transform: uppercase;
        position: relative;
        height: 65px;
        border-top: 1px solid #4A3636;
        align-items: center;
        padding-top: 5px;
        max-width: 100%;
    }
    .marquee-wrapper {
        max-width: 100%;
        overflow: hidden;
    }

    .marquee {
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        animation: marquee 15s linear infinite;
    }

    .marquee p {
        display: inline-block;
    }

    @keyframes marquee {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(-50%, 0, 0);
        }
    }
}
