.root {
  display: flex;
  justify-content: center;
  background: url("./assets/images/bg.webp");
  background-repeat: repeat-y;
  background-size: cover;
}

html {
  scroll-behavior: smooth;
}

.content {
  max-width: 1920px;
  width: 100%;
}

.inner {
  padding: 20px 100px;
}

@media screen and (max-width: 1200px) {
  .content {
    max-width: 1200px;
    width: 100%;
  }
  .inner {
    padding: 10px;
    overflow: hidden;
  }
}
