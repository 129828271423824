.description {
    display: flex;
    border-bottom: 1px solid #4A3636;
    font-family: ZvinRegular, 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.description-left-side {
    width: 50%;
    border-right: 1px solid #4A3636;
    padding: 170px 50px 70px 0;
}

.description-title-first {
    font-size: 120px;
    text-transform: uppercase;
}

.description-title-second {
    font-size: 120px;
    text-transform: uppercase;
    align-content: flex-end;
    text-align: end;
}

.description-img-text-wrapper {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    img {
        width: 318px;
    }
}

.description-img-text {
    max-width: 384px;
    font-size: 25px;
    text-align: end;
    line-height: 35px;
}

.description-right-side {
    width: 50%;
    padding: 185px 50px 70px;
    font-size: 35px;
    line-height: 49px;
}

.description-right-side-top {
    padding-bottom: 45px;

    a {
        background: #FFFFFF99;
        padding: 5px;
        border-radius: 10px;
        color: #4A3636;
    }
}

.description-right-side-mid {
    background: url("../../assets/images/ornament.svg");
    height: 27px;
}

.description-right-side-bottom {
    padding-top: 45px;
}

.description-img-text-wrapper-mob {
    display: none;
}

@media screen and (max-width: 1200px) {
    .description {
        flex-direction: column;
    }
    .description-left-side {
        width: 100%;
        padding: 55px 0 0 0;
        border-right: 0;
        max-width: 400px;
        align-self: center;
    }

    .description-right-side {
        width: 100%;
        padding: 35px 0 0 0;
    }

    .description-title-first {
        font-size: 40px;
        max-width: 274px;
    }

    .description-title-second {
        font-size: 40px;
    }

    .description-right-side-top {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 20px;
    }

    .description-right-side-bottom {
        font-size: 20px;
        line-height: 28px;
        padding-top: 20px;
    }

    .description-img-text-wrapper {
        display: none;
    }

    .description-img-text-wrapper-mob {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        img {
            width: 318px;
            padding: 50px 0 55px 0;
        }
    }

    .description-img-text-inner-mob {
        display: flex;
        flex-direction: column;
        width: 355px;
        align-items: center;
    }

    .description-img-text-mod {
        font-size: 16px;
        line-height: 22px;
        max-width: 304px;
        text-align: right;
        align-self: end;
        padding-bottom: 55px;
    }
}
