.mob-navigation {
    background: rgba(190, 146, 123, 0.65);
    backdrop-filter: blur(15px);
    width: calc(100% - 20px);
    top: 0;
    left: 0;
    height: 345px;
    position: absolute;
    z-index: 100;
    padding: 10px;
    display: none;
}

.mob-navigation--active {
    display: block;
    animation: fadeIn 0.3s ease-in-out;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.mob-navigation-list-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 50px);
}

@media screen and (min-width: 1201px) {
    .mob-navigation {
        display: none;
    }
    .mob-navigation--active {
        display: none;
    }
    .mob-navigation-list-wrapper {
        display: none;
    }
}
