.specialists {
    border-bottom: 1px solid #4A3636;
    padding: 170px 0;
}

.specialists-title {
    font-family: ZvinRegular, 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 120px;
    text-transform: uppercase;
}

.specialists-title-first {
    position: relative;
    text-align: left;
}

.specialists-title-second {
    text-align: right;
}

.specialists-mid {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0;
}

.specialists-mid-inner {
    width: 70%;
    background: url("../../assets/images/ornament.svg");
    height: 27px;
}

.specialists-list {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 10px;
}

.specialists-list-item {
    padding: 25px 30px;
    border-radius: 30px;
    background: #FFFFFF99;
    font-family: ZvinRegular, 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-transform: uppercase;
    font-size: 40px;
}

.specialists-title-text {
    position: relative;
    z-index: 1;
}

.specialists-buy-btn {
    position: absolute;
    top: 65px;
    z-index: 0;
    cursor: pointer;
    transition: all .2s ease-in-out;

    .specialists-buy-btn-img {
        transform: rotate(16deg);
        opacity: 0.7;
        transition: all .2s ease-in-out;
    }
}

.specialists-buy-btn:hover {
    .specialists-buy-btn-img {
        opacity: 1;
        transform: rotate(0deg);
    }
}

@media screen and (min-width: 1201px) and (max-width: 1700px) {
    .specialists {
        padding-top: 100px;
    }
    .specialists-buy-btn {
        top: 165px;
        left: -80px;
    }
}

@media screen and (max-width: 1200px) {
    .specialists {
        padding: 80px 0 70px 0;
    }

    .specialists-title {
        font-size: 40px;
    }

    .specialists-mid {
        display: none;
    }
    .specialists-title-second {
        text-align: start;
    }

    .specialists-buy-btn {
        top: -65px;
        right: 0;
        img {
            width: 165px;
        }
    }

    .specialists-list {
        justify-content: center;
        padding-top: 35px;
    }

    .specialists-list-item {
        font-size: 25px;
        text-align: center;
    }
}
